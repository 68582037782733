<template>
  <div class="filter-dropdown">
    <label>Ангилал</label>
    <div v-if="isLoading"></div>
    <div v-else>
      <Dropdown v-model="cat" :options="catList" :optionValue="getVal" optionLabel="title" placeholder="Ангилал" :filter="true" :showClear="true"
                @change="onChange"
                filterPlaceholder="Ангилал хайх"/>
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';

export default {
  name: "CatDropdown",
  props: ['singleCatMode'],
  components: {
    Dropdown
  },
  data() {
    return {
      isLoading: false,
      cat: null
    }
  },
  computed: {
    catList() {
      return this.$store.state['cats'];
    }
  },
  created() {
    this.$store.dispatch('getCats', {vm: this});
    if(this.$route.params.cat != 'all'){
      this.cat = this.$route.params.cat;
    }
  },
  methods: {
    onChange(val){
      let cat = val.value;
      if(cat == null){
        cat = 'all'
      }

      let season = this.$route.params.season ? this.$route.params.season : 'all';
      let status = this.$route.params.season ? this.$route.params.status : 'all';
      let sort = this.$route.params.sort ? this.$route.params.sort : 'all';
      this.$router.push(`/series/${cat}/${season}/${status}/${sort}`);
    },

    getVal(item){
        return item.id.toString();
    }
  }
}
</script>

<style scoped>

</style>