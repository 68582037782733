<template>
  <div class="theatre-movies">
    <div class="theatre-movies-wrap">
      <div class="media-filter">
        <div class="media-filter-left">
          <CatDropdown :main-cat="2"/>
        </div>

        <div class="media-filter-right">
          <SortDropdown/>
        </div>
      </div>

      <div class="media-list">
        <div v-if="page === 1 && isLoading" class="media-loader-wrap">
          <ProgressSpinner/>
        </div>

        <div v-else class="media-grid-wrapper">
          <div class="media-grid grid gap-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-2">
            <router-link :to="`/theatre/detail/${item.id}`" v-for="item in mediaList"
               :key="item.id"
               class="media-item">
              <figure class="effect-sadie">
                <div class="media-price">{{ item.price ? parseInt(item.price) : 'N/A' }} ₮</div>

                <img :src="Poster" v-lazy:background-image="imageUrl(item.image, true)" class="poster rounded-md"/>

                <figcaption>
                  <h2 class="line-clamp-2">{{ item.title }}</h2>
                  <p class="desc line-clamp-4" v-html="item.description"></p>
                  <div class="item-actions">
                    <div class="tag-wrap">
                      <span v-if="item.year" class="tag">{{ item.year }} он</span>
                      <span v-if="item.age_limit" class="tag"><b>{{ item.age_limit }}+</b></span>
                      <span class="tag">2 h 20 min</span>
                    </div>
                  </div>
                </figcaption>
              </figure>
            </router-link>
          </div>
        </div>
      </div>

      <div v-if="hasMore && !isLoading" class="pagination">
        <ProgressSpinner v-if="isLoadingMore" style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
        <Button v-else type="button" @click="getMore(this.page + 1)">
          <span>Цааш</span>
          <i class="pi pi-angle-double-down"></i>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {setModalActive} from "../../store";
import {imageUrl} from "../../utils";
import Poster from "../../assets/poster.png";
import CatDropdown from "@components/CatDropdown";
import SortDropdown from "@components/SortDropdown";

export default {
  name: "TheatreMedia",
  components: {
    CatDropdown,
    SortDropdown
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      cat: null,
      hasMore: false,
      page: 1,
      Poster
    }
  },
  computed: {
    ...mapGetters(['mediaList']),
  },

  created() {
    let payload = {
      vm: this,
      m: 3,
      cat: this.$route.params.cat ? this.$route.params.cat : 'all',
      sort: this.$route.params.sort ? this.$route.params.sort : 'all',
      page: this.page,
    }

    this.$store.dispatch('getMediaList', payload);
  },
  methods: {
    imageUrl,
    handleClick(animeID, epID) {
      if (this.mirror) {
        this.$router.push(`/watch/${animeID}/${epID}`)
      } else {
        this.$router.replace({query: {t: this.$route.query.t, media: animeID}});
        setModalActive(true);
      }
    },

    getMore(page) {
      this.isLoadingMore = true;
      let payload = {
        vm: this,
        m: 3,
        cat: this.$route.params.cat ? this.$route.params.cat : 'all',
        sort: this.$route.params.sort ? this.$route.params.sort : 'all',
        page: page,
        isMore: true
      }

      this.$store.dispatch('getMediaList', payload)
    }
  }
}
</script>

<style scoped>

</style>